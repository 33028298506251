.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  position: relative;
  font-style: oblique;
  top: 30px;
  justify-content: center;
  
}

.App-header {
  display: flex;
  justify-content: center;
  position: relative;
  font-style: oblique;
  top: 10px;
  

  
}
.App-footer {
  display: flex;
  justify-content: center;
  position: relative;
  font-style: oblique;
  top: 10px;
  
}

.Pro-footer{
  display: flex;
  justify-content: center;
  position: relative;
  font-style: oblique;
  bottom: 10px;
  
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Icon {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  position: relative;
}

.TextJum{
  display: flex;
  justify-content: left;
  position: relative;
  
  top: 20px; 
}
.Center{
  display: flex;
  
  position: relative;
  left: 10px;
  top: 10px; 
}
.Icons{
  position: relative;
  display: flex;
  top: 10px;
  
   

}
.Image-col{
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  position: relative;
}

.Jumbo{
  background-color: rgb(97, 149, 12);
}
.Carousel{
  width: 400px;
  height: 250px;
}
